const IconRotationBenne = () => {
  return (
    <svg id="Rotation-Benne" xmlns="http://www.w3.org/2000/svg" width="60" height="80" viewBox="0 0 60 80">
      <rect id="Rectangle_1431" data-name="Rectangle 1431" width="60" height="80" fill="#fff" opacity="0.004" />
      <g id="Groupe_1415" data-name="Groupe 1415" transform="translate(4 4.595)">
        <g id="Groupe_1412" data-name="Groupe 1412" transform="translate(0 0)">
          <path id="Tracé_4312" data-name="Tracé 4312" d="M84.392,55.477V52.386a9.648,9.648,0,0,1-13.9-12.974v-3.2h-3.36A14.238,14.238,0,0,0,87.868,55.477Z" transform="translate(-40.261 -30.058)" fill="#e75012" />
          <path id="Tracé_4314" data-name="Tracé 4314" d="M97.786,5.7v3.09a9.648,9.648,0,0,1,13.9,12.822v3.355h3.279A14.238,14.238,0,0,0,94.288,5.7Z" transform="translate(-65.122 -1.979)" fill="#e75012" />
          <path id="Tracé_4316" data-name="Tracé 4316" d="M52.508,212.352l3.216-3.484,3.35.8,4.69-2.144,5.092,1.206,5.628,1.206,4.556-1.876,4.288,1.608,3.752,2.68Z" transform="translate(-43.588 -172.272)" fill="#95c515" />
          <path id="Tracé_4317" data-name="Tracé 4317" d="M82.981,210.241H45.656l4.208-4.559,3.47.833,4.689-2.144,5.262,1.246,5.463,1.171,4.595-1.892,4.538,1.7Zm-35-1.019H79.8L77.4,207.5l-4.037-1.514-4.517,1.86-5.782-1.239-4.932-1.168-4.691,2.144-3.23-.775Z" transform="translate(-37.9 -169.651)" fill="#4a4b4f" />
          <path id="Tracé_4318" data-name="Tracé 4318" d="M58.017,285.269H23.589l-5.833-21.111H64.263Z" transform="translate(-14.74 -219.281)" fill="#fff" />
          <path id="Tracé_4319" data-name="Tracé 4319" d="M55.527,283.289H21.1a.509.509,0,0,1-.491-.374L14.775,261.8a.51.51,0,0,1,.491-.645H61.773a.51.51,0,0,1,.489.654l-6.246,21.111a.51.51,0,0,1-.489.365m-34.04-1.019h33.66l5.944-20.092H15.936Z" transform="translate(-12.249 -216.791)" fill="#4a4b4f" />
          <rect id="Rectangle_1427" data-name="Rectangle 1427" width="51.521" height="5.162" transform="translate(0.51 39.715)" fill="#fff" />
          <path id="Tracé_4320" data-name="Tracé 4320" d="M52.03,236.956H.51a.51.51,0,0,1-.51-.51v-5.162a.51.51,0,0,1,.51-.51H52.03a.51.51,0,0,1,.51.51v5.162a.51.51,0,0,1-.51.51M1.019,235.937h50.5v-4.142H1.019Z" transform="translate(0 -191.569)" fill="#4a4b4f" />
          <path id="Tracé_4321" data-name="Tracé 4321" d="M141.442,298.623H140.4a1.733,1.733,0,0,1-1.734-1.733V285.336A1.733,1.733,0,0,1,140.4,283.6h1.044a1.733,1.733,0,0,1,1.733,1.733V296.89a1.733,1.733,0,0,1-1.733,1.733" transform="translate(-115.108 -235.423)" fill="#95c515" />
          <path id="Tracé_4322" data-name="Tracé 4322" d="M138.952,296.643h-1.044a2.246,2.246,0,0,1-2.243-2.243V282.846a2.246,2.246,0,0,1,2.243-2.243h1.044a2.246,2.246,0,0,1,2.243,2.243V294.4a2.246,2.246,0,0,1-2.243,2.243m-1.044-15.021a1.225,1.225,0,0,0-1.224,1.224V294.4a1.225,1.225,0,0,0,1.224,1.224h1.044a1.225,1.225,0,0,0,1.224-1.224V282.846a1.225,1.225,0,0,0-1.224-1.224Z" transform="translate(-112.617 -232.932)" fill="#4a4b4f" />
          <path id="Tracé_4323" data-name="Tracé 4323" d="M210.476,296.139h-.911a1.8,1.8,0,0,1-1.8-1.8V285.4a1.8,1.8,0,0,1,1.8-1.8h.911a1.8,1.8,0,0,1,1.8,1.8v8.937a1.8,1.8,0,0,1-1.8,1.8" transform="translate(-172.468 -235.423)" fill="#95c515" />
          <path id="Tracé_4324" data-name="Tracé 4324" d="M207.985,294.159h-.911a2.312,2.312,0,0,1-2.309-2.309v-8.937a2.312,2.312,0,0,1,2.309-2.309h.911a2.312,2.312,0,0,1,2.309,2.309v8.937a2.312,2.312,0,0,1-2.309,2.309m-.911-12.536a1.292,1.292,0,0,0-1.29,1.29v8.937a1.292,1.292,0,0,0,1.29,1.29h.911a1.292,1.292,0,0,0,1.29-1.29v-8.937a1.292,1.292,0,0,0-1.29-1.29Z" transform="translate(-169.977 -232.932)" fill="#4a4b4f" />
          <path id="Tracé_4325" data-name="Tracé 4325" d="M72.278,296.139h-.911a1.8,1.8,0,0,1-1.8-1.8V285.4a1.8,1.8,0,0,1,1.8-1.8h.911a1.8,1.8,0,0,1,1.8,1.8v8.937a1.8,1.8,0,0,1-1.8,1.8" transform="translate(-57.748 -235.423)" fill="#95c515" />
          <path id="Tracé_4326" data-name="Tracé 4326" d="M69.788,294.159h-.911a2.312,2.312,0,0,1-2.309-2.309v-8.937a2.312,2.312,0,0,1,2.309-2.309h.911a2.312,2.312,0,0,1,2.309,2.309v8.937a2.312,2.312,0,0,1-2.309,2.309m-.911-12.536a1.292,1.292,0,0,0-1.29,1.29v8.937a1.292,1.292,0,0,0,1.29,1.29h.911a1.292,1.292,0,0,0,1.29-1.29v-8.937a1.292,1.292,0,0,0-1.29-1.29Z" transform="translate(-55.258 -232.932)" fill="#4a4b4f" />
        </g>
      </g>
    </svg>
  );
};

export default IconRotationBenne;
