import request from './request';
import { PupsOrder } from '../models';

const orderService = {
  commandesEnCours: (pupsLocationId: string | number) =>
    request.get<{ pups_order: PupsOrder[] }>(
      `/pups-locations/${pupsLocationId}/commandes-en-cours`
    ),
  findCommandesEnCour: (
    pupsLocationId: string | number,
    orderId: string | number
  ) =>
    request.get(
      `/pups-locations/${pupsLocationId}/commandes-en-cours/${orderId}`
    ),
  commandesRealisees: (query: any) =>
    request.post<{ pups_order: PupsOrder[] }>(`/commandes-realisees`, query),
  findCommandesRealise: (
    pupsLocationId: string | number,
    orderId: string | number
  ) =>
    request.get(
      `/pups-locations/${pupsLocationId}/commandes-realisees/${orderId}`
    ),
  updateComments: (
    pupsLocationId: string | number,
    orderId: string | number,
    data: any
  ) =>
    request.put(
      `/pups-locations/${pupsLocationId}/commandes-en-cours/${orderId}/update-comments`,
      data
    ),
  cancelOrder: (orderId: string | number) =>
    request.put(`/cancel-order/${orderId}`)
};

export default orderService;
