const IconBack = () => {
  return (
    <svg id="back" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="Rectangle_1416" data-name="Rectangle 1416" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0.001">
        <rect width="28" height="28" stroke="none" />
        <rect x="0.5" y="0.5" width="27" height="27" fill="none" />
      </g>
      <g id="Groupe_1350" data-name="Groupe 1350" transform="translate(8 4)">
        <g id="right-arrow" transform="translate(9.636 18) rotate(180)">
          <path id="Tracé_24" data-name="Tracé 24" d="M1.086,17.814A.621.621,0,0,1,.636,18a.621.621,0,0,1-.45-.186.637.637,0,0,1,0-.9L8.1,9,.186,1.086a.636.636,0,0,1,.9-.9L9.45,8.55a.637.637,0,0,1,0,.9Z" fill="#1a2223" />
        </g>
      </g>
    </svg>

  );
};

export default IconBack;
