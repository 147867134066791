const IconPhone = () => {
  return (
    <svg id="item" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <g id="phone">
        <path id="Tracé_1" data-name="Tracé 1" d="M25.995,21.152c0-.6.067-.978-.836-1.279-1.276-.377-6.273-2.766-6.792-2.764a9.175,9.175,0,0,0-2.284,1.847,1.451,1.451,0,0,1-1.612.268,31.432,31.432,0,0,1-4.444-3.313,30.347,30.347,0,0,1-3.64-4.6,1.522,1.522,0,0,1,.292-1.628,10.856,10.856,0,0,0,1.908-2.3A8.272,8.272,0,0,0,7.948,5.5C7.24,3.7,6.4,1.914,5.761.2a1.509,1.509,0,0,0-1-.2A5.8,5.8,0,0,0,2.049.758C.614,1.851.015,4.366,0,5.8-.066,10.789,4.252,16.832,9.825,21.5,13.305,24.3,26.07,30.225,25.995,21.152Z" transform="translate(0.004 0)" fill="#fff" fillRule="evenodd" />
      </g>
    </svg>

  );
};

export default IconPhone;
