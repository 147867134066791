const IconEnter = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17.138" viewBox="0 0 17 17.138">
      <g id="enter" transform="translate(0.5 0.638)">
        <path id="Tracé_4401" data-name="Tracé 4401" d="M7.833,7.289l3,3-3,3" transform="translate(-0.52 -0.489)" fill="none" stroke="#777" strokeLinecap="round" strokeWidth="1" />
        <g id="Groupe_1490" data-name="Groupe 1490">
          <g id="Groupe_1492" data-name="Groupe 1492">
            <line id="Ligne_149" data-name="Ligne 149" x2="9.5" transform="translate(0 9.946)" fill="none" stroke="#777" strokeLinecap="round" strokeWidth="1" />
            <path id="Tracé_4402" data-name="Tracé 4402" d="M3.824,13.749v2.595h12V5.1l-6-4.757-6,4.757V6.83" transform="translate(0.176 -0.343)" fill="none" stroke="#777" strokeLinecap="round" strokeWidth="1" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconEnter;
