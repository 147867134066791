const IconMail = () => {
  return (
    <svg id="mail" xmlns="http://www.w3.org/2000/svg" width="33" height="23" viewBox="0 0 33 23">
      <path id="Tracé_2697" data-name="Tracé 2697" d="M603.023,787.367a2.65,2.65,0,0,1-.307-1.243v-17.71a2.67,2.67,0,0,1,.219-1.06l12.929,10.041Z" transform="translate(-602.716 -765.769)" fill="#fff" />
      <path id="Tracé_2698" data-name="Tracé 2698" d="M651.733,741.905a1.107,1.107,0,0,1-.684-.239l-14.375-11.379a2.52,2.52,0,0,1,1.171-.287h27.776a2.52,2.52,0,0,1,1.171.287l-14.375,11.379A1.108,1.108,0,0,1,651.733,741.905Z" transform="translate(-635.233 -730)" fill="#fff" />
      <path id="Tracé_2699" data-name="Tracé 2699" d="M1087.405,777.395l12.929-10.041a2.666,2.666,0,0,1,.219,1.06v17.71a2.649,2.649,0,0,1-.307,1.243Z" transform="translate(-1067.554 -765.769)" fill="#fff" />
      <path id="Tracé_2700" data-name="Tracé 2700" d="M642.443,1039.582a2.531,2.531,0,0,1-.972-.194l12.649-10.016.463.366a2.8,2.8,0,0,0,3.487,0l.463-.366,12.649,10.016a2.531,2.531,0,0,1-.972.194Z" transform="translate(-639.827 -1016.582)" fill="#fff" />
    </svg>
  );
};

export default IconMail;
