import { Moment } from 'moment';
import { createSlice } from '@reduxjs/toolkit';

const name = 'commandesRealiseesFiltrer';

interface CommandesRealiseesFiltrerState {
  dataCommandesRealiseesFiltrer: {
    pups_order_type: string[];
    date_start: Moment | string;
    date_end: Moment | string;
  };
}

const initialState: CommandesRealiseesFiltrerState = {
  dataCommandesRealiseesFiltrer: {
    pups_order_type: [],
    date_start: '',
    date_end: '',
  },
};

const commandesRealiseesFiltrerSlice = createSlice({
  name,
  initialState,
  reducers: {
    dataCommandesRealiseesFiltrerChange: (state, action) => {
      console.log(action.payload)
      state.dataCommandesRealiseesFiltrer = action.payload;
    },
  },
});
const { reducer, actions } = commandesRealiseesFiltrerSlice;
export const commandesRealiseesFiltrerChange =
  actions.dataCommandesRealiseesFiltrerChange;
export default reducer;
