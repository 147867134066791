
const IconFilterArrow = () => {
  return (
    <svg id="filter-arrow" xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12">
      <path id="Tracé_24" data-name="Tracé 24" d="M.676,11.876a.378.378,0,0,1-.56,0,.446.446,0,0,1,0-.6L5.043,6,.116.724a.446.446,0,0,1,0-.6.378.378,0,0,1,.56,0L5.884,5.7a.446.446,0,0,1,0,.6Z" fill="#1a2223" />
    </svg>

  );
};

export default IconFilterArrow;
