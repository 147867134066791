const IconBurger = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="burger" transform="translate(-317 -5)">
        <g id="Tracé_4396" data-name="Tracé 4396" transform="translate(317 5)" fill="#fff" opacity="0">
          <path d="M 47.5 47.5 L 0.5 47.5 L 0.5 0.5 L 47.5 0.5 L 47.5 47.5 Z" stroke="none" />
          <path d="M 1 1 L 1 47 L 47 47 L 47 1 L 1 1 M 0 0 L 48 0 L 48 48 L 0 48 L 0 0 Z" stroke="none" fill="#707070" />
        </g>
        <g id="Groupe_6" data-name="Groupe 6" transform="translate(329 19)">
          <path id="Tracé_19" data-name="Tracé 19" d="M.629,154.924H23.371c.348,0,.629-.427.629-.962s-.286-.962-.629-.962H.629c-.348,0-.629.427-.629.962S.286,154.924.629,154.924Z" transform="translate(0 -153)" fill="#fff" />
          <path id="Tracé_20" data-name="Tracé 20" d="M.629,294.015H23.371c.348,0,.629-.427.629-.962s-.286-.962-.629-.962H.629c-.348,0-.629.427-.629.962S.286,294.015.629,294.015Z" transform="translate(0 -283.053)" fill="#fff" />
          <path id="Tracé_21" data-name="Tracé 21" d="M.629,433.106H23.371c.348,0,.629-.427.629-.962s-.286-.962-.629-.962H.629c-.348,0-.629.427-.629.962S.286,433.106.629,433.106Z" transform="translate(0 -413.106)" fill="#fff" />
        </g>
      </g>
    </svg>
  );
};

export default IconBurger;
