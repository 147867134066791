const IconCollecterMesDechets = () => {
  return (
    <svg id="Collecter_mes_déchets" data-name="Collecter mes déchets" xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72">
      <rect id="Rectangle_1467" data-name="Rectangle 1467" width="72" height="72" fill="#fff" opacity="0" />
      <g id="Groupe_1424" data-name="Groupe 1424" transform="translate(6 13.505)">
        <path id="Union_6" data-name="Union 6" d="M16.438,10.529a.832.832,0,0,1-.514-.768V6.944H1.647a1.647,1.647,0,1,1,0-3.294H15.924V.832a.832.832,0,0,1,1.42-.588l4.464,4.464a.833.833,0,0,1,0,1.177l-4.464,4.464a.832.832,0,0,1-.906.18Z" transform="translate(37.951 0.495)" fill="#e75012" stroke="rgba(0,0,0,0)" strokeWidth="1" />
        <path id="Tracé_4339" data-name="Tracé 4339" d="M16.289,44.566l3.053-3.307,3.18.763,4.452-2.035,4.834,1.145,5.342,1.145L41.475,40.5l4.071,1.526,3.562,2.544Z" transform="translate(-13.662 -33.48)" fill="#95c515" />
        <path id="Tracé_4340" data-name="Tracé 4340" d="M44.869,42.4H9.437l3.995-4.328,3.294.79,4.451-2.035,5,1.183,5.186,1.111,4.362-1.8,4.308,1.615Zm-33.222-.968h30.2l-2.281-1.629-3.833-1.437-4.288,1.766-5.489-1.176-4.682-1.109-4.453,2.035-3.066-.736Z" transform="translate(-7.915 -30.832)" fill="#4a4b4f" />
        <path id="Tracé_4341" data-name="Tracé 4341" d="M47.483,101.628H20.946l-4.9-14.076H49.1Z" transform="translate(-13.462 -73.732)" fill="#fff" />
        <path id="Tracé_4342" data-name="Tracé 4342" d="M44.967,99.6H18.43a.484.484,0,0,1-.457-.325L13.076,85.2a.484.484,0,0,1,.457-.643H46.579a.484.484,0,0,1,.481.539L45.447,99.168a.484.484,0,0,1-.481.429m-26.193-.968H44.535l1.5-13.108H14.214Z" transform="translate(-10.945 -71.215)" fill="#4a4b4f" />
        <rect id="Rectangle_1434" data-name="Rectangle 1434" width="37.255" height="3.442" transform="translate(0.484 10.538)" fill="#fff" />
        <path id="Tracé_4343" data-name="Tracé 4343" d="M37.739,67.622H.484A.484.484,0,0,1,0,67.138V63.7a.484.484,0,0,1,.484-.484H37.739a.484.484,0,0,1,.484.484v3.441a.484.484,0,0,1-.484.484M.968,66.654H37.255V64.18H.968Z" transform="translate(0 -53.154)" fill="#4a4b4f" />
        <path id="Tracé_4344" data-name="Tracé 4344" d="M250.98,116.24h2.751V105.958a2.667,2.667,0,0,0-1.556-2.779c-1.612-.667-4.946-2-4.946-2s-3.5-7.17-3.779-7.837a2.736,2.736,0,0,0-2.557-1.612h-9.337v24.455s19.52-.039,19.425.056" transform="translate(-194.212 -77.682)" fill="#fff" />
        <path id="Tracé_4345" data-name="Tracé 4345" d="M251.214,114.209h-2.751a.479.479,0,0,1-.106-.012c-.837-.059-11.951-.058-19.318-.044h0a.484.484,0,0,1-.484-.484V89.215a.484.484,0,0,1,.484-.484h9.337a3.2,3.2,0,0,1,3,1.909c.211.506,2.456,5.124,3.686,7.643.706.283,3.393,1.36,4.779,1.933a3.128,3.128,0,0,1,1.855,3.174v10.335a.484.484,0,0,1-.484.484m-2.734-.968h2.251v-9.854a2.165,2.165,0,0,0-1.257-2.277c-1.591-.658-4.908-1.985-4.941-2a.484.484,0,0,1-.255-.237c-.143-.293-3.511-7.19-3.791-7.863a2.266,2.266,0,0,0-2.11-1.314h-8.854v23.486c1.348,0,5.3-.008,9.215-.007,2.818,0,5.064.006,6.678.014.913,0,1.675.011,2.21.019.41.006.676.01.855.03" transform="translate(-191.694 -75.167)" fill="#4a4b4f" />
        <path id="Tracé_4346" data-name="Tracé 4346" d="M258.189,118.081h-7.845a1.02,1.02,0,0,1-1.02-1.02v-6.138a1.02,1.02,0,0,1,1.02-1.02h5.039a1.02,1.02,0,0,1,.928.6l2.805,6.138a1.02,1.02,0,0,1-.928,1.444" transform="translate(-209.115 -92.499)" fill="#fff" />
        <path id="Tracé_4347" data-name="Tracé 4347" d="M255.673,116.048h-7.845a1.506,1.506,0,0,1-1.5-1.5v-6.138a1.506,1.506,0,0,1,1.5-1.5h5.039a1.508,1.508,0,0,1,1.368.879l2.805,6.138a1.5,1.5,0,0,1-1.368,2.129m-7.845-8.179a.537.537,0,0,0-.537.537v6.138a.537.537,0,0,0,.537.536h7.845a.536.536,0,0,0,.488-.759l-2.805-6.138a.538.538,0,0,0-.488-.314Z" transform="translate(-206.599 -89.981)" fill="#4a4b4f" />
        <path id="Tracé_4348" data-name="Tracé 4348" d="M351.149,176.7h-.91a2.41,2.41,0,0,1,0-4.82h.91v.968h-.91a1.442,1.442,0,0,0,0,2.885h.91Z" transform="translate(-291.735 -144.647)" fill="#4a4b4f" />
        <line id="Ligne_123" data-name="Ligne 123" x2="1.594" y2="0.152" transform="translate(25.015 28.129)" fill="#fff" />
        <rect id="Rectangle_1435" data-name="Rectangle 1435" width="0.968" height="1.601" transform="translate(24.969 28.608) rotate(-84.556)" fill="#161615" />
        <rect id="Rectangle_1436" data-name="Rectangle 1436" width="30.275" height="11.244" transform="translate(7.074 27.266)" fill="#fff" />
        <path id="Tracé_4349" data-name="Tracé 4349" d="M71.624,182.9H41.349a.484.484,0,0,1-.484-.484V171.175a.484.484,0,0,1,.484-.484H71.624a.484.484,0,0,1,.484.484v11.245a.484.484,0,0,1-.484.484m-29.791-.968H71.14V171.659H41.833Z" transform="translate(-34.275 -143.909)" fill="#4a4b4f" />
        <path id="Tracé_4350" data-name="Tracé 4350" d="M94.392,216.379a5.363,5.363,0,1,1-5.363-5.363,5.363,5.363,0,0,1,5.363,5.363" transform="translate(-70.173 -177.731)" fill="#fff" />
        <path id="Tracé_4351" data-name="Tracé 4351" d="M86.513,219.708a5.847,5.847,0,1,1,5.847-5.847,5.853,5.853,0,0,1-5.847,5.847m0-10.726a4.879,4.879,0,1,0,4.879,4.879,4.884,4.884,0,0,0-4.879-4.879" transform="translate(-67.657 -175.214)" fill="#4a4b4f" />
        <path id="Tracé_4352" data-name="Tracé 4352" d="M105.471,230.1a2.725,2.725,0,1,1-2.725-2.725,2.725,2.725,0,0,1,2.725,2.725" transform="translate(-83.89 -191.447)" fill="#fff" />
        <path id="Tracé_4353" data-name="Tracé 4353" d="M100.229,230.788a3.209,3.209,0,1,1,3.209-3.209,3.213,3.213,0,0,1-3.209,3.209m0-5.451a2.241,2.241,0,1,0,2.242,2.241,2.244,2.244,0,0,0-2.242-2.241" transform="translate(-81.373 -188.931)" fill="#4a4b4f" />
        <path id="Tracé_4354" data-name="Tracé 4354" d="M296.73,216.379a5.363,5.363,0,1,1-5.363-5.363,5.363,5.363,0,0,1,5.363,5.363" transform="translate(-239.879 -177.731)" fill="#fff" />
        <path id="Tracé_4355" data-name="Tracé 4355" d="M288.851,219.708a5.847,5.847,0,1,1,5.847-5.847,5.853,5.853,0,0,1-5.847,5.847m0-10.726a4.879,4.879,0,1,0,4.879,4.879,4.884,4.884,0,0,0-4.879-4.879" transform="translate(-237.363 -175.214)" fill="#4a4b4f" />
        <path id="Tracé_4356" data-name="Tracé 4356" d="M307.809,230.1a2.725,2.725,0,1,1-2.725-2.725,2.725,2.725,0,0,1,2.725,2.725" transform="translate(-253.596 -191.447)" fill="#fff" />
        <path id="Tracé_4357" data-name="Tracé 4357" d="M302.567,230.788a3.209,3.209,0,1,1,3.209-3.209,3.213,3.213,0,0,1-3.209,3.209m0-5.451a2.241,2.241,0,1,0,2.242,2.241,2.244,2.244,0,0,0-2.242-2.241" transform="translate(-251.08 -188.931)" fill="#4a4b4f" />
        <path id="Tracé_4358" data-name="Tracé 4358" d="M112.262,110.209h-.232a1.107,1.107,0,0,1-1.107-1.107v-6.785a1.107,1.107,0,0,1,1.107-1.107h.232a1.107,1.107,0,0,1,1.107,1.107V109.1a1.107,1.107,0,0,1-1.107,1.107" transform="translate(-93.034 -85.186)" fill="#95c515" />
        <path id="Tracé_4359" data-name="Tracé 4359" d="M109.746,108.176h-.232a1.592,1.592,0,0,1-1.59-1.59V99.8a1.592,1.592,0,0,1,1.59-1.591h.232a1.593,1.593,0,0,1,1.591,1.591v6.785a1.592,1.592,0,0,1-1.591,1.59m-.232-9a.624.624,0,0,0-.623.623v6.785a.624.624,0,0,0,.623.623h.232a.624.624,0,0,0,.623-.623V99.8a.624.624,0,0,0-.623-.623Z" transform="translate(-90.518 -82.668)" fill="#4a4b4f" />
        <path id="Tracé_4360" data-name="Tracé 4360" d="M162.736,108.177H162.5a1.107,1.107,0,0,1-1.107-1.107v-4.754a1.107,1.107,0,0,1,1.107-1.107h.232a1.107,1.107,0,0,1,1.107,1.107v4.754a1.107,1.107,0,0,1-1.107,1.107" transform="translate(-135.368 -85.167)" fill="#95c515" />
        <path id="Tracé_4361" data-name="Tracé 4361" d="M160.22,106.145h-.232a1.592,1.592,0,0,1-1.59-1.591V99.8a1.592,1.592,0,0,1,1.59-1.591h.232a1.592,1.592,0,0,1,1.59,1.591v4.754a1.592,1.592,0,0,1-1.59,1.591m-.232-6.967a.624.624,0,0,0-.623.623v4.754a.624.624,0,0,0,.623.623h.232a.624.624,0,0,0,.623-.623V99.8a.624.624,0,0,0-.623-.623Z" transform="translate(-132.852 -82.649)" fill="#4a4b4f" />
        <path id="Tracé_4362" data-name="Tracé 4362" d="M61.788,108.177h-.232a1.107,1.107,0,0,1-1.107-1.107v-4.754a1.107,1.107,0,0,1,1.107-1.107h.232a1.107,1.107,0,0,1,1.107,1.107v4.754a1.107,1.107,0,0,1-1.107,1.107" transform="translate(-50.7 -85.167)" fill="#95c515" />
        <path id="Tracé_4363" data-name="Tracé 4363" d="M59.271,106.145h-.232a1.593,1.593,0,0,1-1.591-1.591V99.8a1.593,1.593,0,0,1,1.591-1.591h.232A1.592,1.592,0,0,1,60.861,99.8v4.754a1.592,1.592,0,0,1-1.591,1.591m-.232-6.967a.624.624,0,0,0-.623.623v4.754a.624.624,0,0,0,.623.623h.232a.624.624,0,0,0,.623-.623V99.8a.624.624,0,0,0-.623-.623Z" transform="translate(-48.183 -82.649)" fill="#4a4b4f" />
      </g>
    </svg>
  );
};

export default IconCollecterMesDechets;
