const IconReturn = () => {
  return (
    <svg id="return" xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22">
      <path id="Tracé_4401" data-name="Tracé 4401" d="M.651,7.3A.65.65,0,0,1,.26,6.13L3.567,3.65.26,1.17A.65.65,0,0,1,1.04.13l4,3a.65.65,0,0,1,0,1.04l-4,3A.647.647,0,0,1,.651,7.3Z" transform="translate(5.3 16.61) rotate(180)" />
      <path id="Ligne_149" data-name="Ligne 149" d="M14,.65H0A.65.65,0,0,1-.65,0,.65.65,0,0,1,0-.65H14a.65.65,0,0,1,.65.65A.65.65,0,0,1,14,.65Z" transform="translate(2.15 12.971)" />
      <path id="Tracé_4402" data-name="Tracé 4402" d="M20.034,21.693H3.813a.64.64,0,0,1-.639-.641V16.94a.639.639,0,1,1,1.278,0v3.47H19.4V6.813L11.924,1.139,4.452,6.813V8.733a.639.639,0,1,1-1.278,0V6.494a.642.642,0,0,1,.253-.511L11.538-.177a.637.637,0,0,1,.771,0L20.42,5.982a.642.642,0,0,1,.253.511V21.052A.64.64,0,0,1,20.034,21.693Z" transform="translate(5.326 0.307)" />
    </svg>
  );
};

export default IconReturn;
