const Icon1Sur3 = () => {
  return (
    <svg id="_1sur3" data-name="1sur3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48">
      <defs>
        <linearGradient id="linear-gradient" x1="0.318" y1="0.437" x2="0.975" y2="0.42" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#99c81c" />
          <stop offset="1" stopColor="#bde05d" />
        </linearGradient>
      </defs>
      <g id="Groupe_1408" data-name="Groupe 1408" transform="translate(-307.01 -68.043)">
        <path id="Tracé_4327" data-name="Tracé 4327" d="M191.645,12.686a18.773,18.773,0,1,1-18.773,18.773,18.794,18.794,0,0,1,18.773-18.773m0-5.227a24,24,0,1,0,24,24,24,24,0,0,0-24-24" transform="translate(139.365 60.584)" fill="#d5d7d6" />
        <path id="Tracé_4328" data-name="Tracé 4328" d="M221.7,49.995a2.615,2.615,0,0,1-1.771-4.543A18.784,18.784,0,0,0,207.215,12.8a2.615,2.615,0,1,1,0-5.23,24.013,24.013,0,0,1,16.253,41.733,2.613,2.613,0,0,1-1.77.687" transform="translate(123.734 60.536)" fill="url(#linear-gradient)" />
      </g>
      <text id="_1_sur_3" data-name="1 sur 3" transform="translate(24.005 27.043)" fill="#4a4b4f" fontSize="10" fontWeight="500">
        <tspan y="0" x="-15">1</tspan>
        <tspan y="0" x="-7" xmlSpace="preserve">sur</tspan>
        <tspan y="0" x="8">3</tspan>
      </text>
    </svg>
  );
};

export default Icon1Sur3;
