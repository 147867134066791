const IconPassword = () => {
  return (
    <svg id="password" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path id="Tracé_4399" data-name="Tracé 4399" d="M0,0H18V18H0Z" fill="#fff" opacity="0" />
      <path id="Tracé_4229" data-name="Tracé 4229" d="M254.816,281.741,253,279.8l.75-.766.7.746a.577.577,0,0,0,.841.01l.349-.356a.642.642,0,0,0,.01-.879l-2.188-2.339a.577.577,0,0,0-.841-.01l-.349.356a.641.641,0,0,0-.01.879l.7.746-.75.766-2.665-2.85a.894.894,0,0,0-.327-.221c1.393-1.876,1.586-4.218.352-5.538-1.392-1.489-4.072-1.112-5.986.841s-2.337,4.744-.945,6.233c1.237,1.324,3.493,1.172,5.323-.25a.944.944,0,0,0,.2.344l5.271,5.637a.878.878,0,0,0,1.28.016l.085-.087A.976.976,0,0,0,254.816,281.741Zm-8.678-5.565a1.546,1.546,0,0,1-2.254-.028,1.719,1.719,0,0,1,.026-2.355,1.542,1.542,0,0,1,1.839-.29,1.72,1.72,0,0,1,.321-1.914,1.546,1.546,0,0,1,2.254.028,1.719,1.719,0,0,1-.026,2.355,1.543,1.543,0,0,1-1.839.29A1.72,1.72,0,0,1,246.138,276.176Z" transform="translate(-239.819 -267.435)" fill="#777" />
    </svg>
  );
};

export default IconPassword;
