import { StepType } from '../../pages/CollecterMesDechets';

const Steps = ({
  steps,
  currentStep,
}: {
  steps: StepType[];
  currentStep: number;
}) => {
  return (
    <div className="custom-steps">
      {steps.map((item, index) => (
        <div
          key={index}
          className={`custom-steps-item ${
            currentStep === index ? 'custom-steps-item-active' : ''
          }`}
        >
          <div className="custom-steps-item-container">
            <div className="custom-steps-item-tail"></div>
            <div className="custom-steps-item-icon">
              <span className="custom-steps-icon">{index + 1}</span>
            </div>
            <div className="custom-steps-item-content">
              <div className="custom-steps-item-title"> {item.title} </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Steps;
