import request from './request';
import { PupsAccount, PupsLocation } from '../models';

const accountService = {
  signIn: (query: { email: string; password: string }) =>
    request.post<{ access_token: string }>('/oauth-pups-service', query),
  signOut: () => request.get('/logout-pups-account'),
  profile: () => request.get<{ user: PupsAccount }>('/pups-account'),
  getLocationGeneralInfo: (id: string | number) =>
    request.get<{ account: PupsLocation; totalEnCours: number }>(
      `/pups-location-general-info/${id}`
    ),
  getPupsLocations: (query: any) =>
    request.get<{ data: PupsLocation[] }>('/list-management-pups-locations', {
      params: query,
    }),
  forgetPassword: (query: any) => request.post('/forget-password', query),
};

export default accountService;
