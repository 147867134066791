import { ReactNode, useState } from 'react';
import Header from './Header';

const Layout = ({ children }: { children: ReactNode }) => {
  const [visibleMask, setVisibleMask] = useState(false);

  const onVisibleDrawerChange = (value: boolean) => {
    setVisibleMask(value);
  };

  return (
    <div className="layout">
      <Header onVisibleDrawerChange={onVisibleDrawerChange} />
      {children}
      {visibleMask && <div className="mask-overlay"></div>}
    </div>
  );
};

export default Layout;
