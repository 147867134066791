const IconMailConnexion = () => {
  return (
    <svg id="mail-connexion" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="Groupe_1475" data-name="Groupe 1475" transform="translate(-39 -312)">
        <rect id="Rectangle_1469" data-name="Rectangle 1469" width="18" height="18" rx="2" transform="translate(39 312)" fill="#fff" opacity="0" />
        <path id="Tracé_4228" data-name="Tracé 4228" d="M256.784,174.689H243.648a1.4,1.4,0,0,0-1.415,1.219,2.658,2.658,0,0,0-.007.692v8.742a1.388,1.388,0,0,0,1.422,1.347h13.136a1.388,1.388,0,0,0,1.422-1.347v-9.307A1.388,1.388,0,0,0,256.784,174.689Zm-13.136,1.1h13.136a.261.261,0,0,1,.2.1c.076,1.127-.065,1.516-.3,1.746-.4.392-3.12,2.632-4.861,3.893-1.272.921-1.441.91-1.619.9-.059,0-.13-.009-.214-.009a40.152,40.152,0,0,1-5.421-4.023,3.054,3.054,0,0,1-1.185-1.837v-.517A.255.255,0,0,1,243.648,175.789Zm13.136,9.8H243.648a.255.255,0,0,1-.261-.247v-6.513c.125.127.262.255.415.383,1.015.852,2.339,1.9,3.374,2.669,2.21,1.644,2.542,1.638,2.785,1.631.062,0,.114,0,.163.006.065,0,.126.007.187.007.479,0,.907-.176,2.222-1.127,1.407-1.019,3.523-2.729,4.514-3.578v6.521A.255.255,0,0,1,256.784,185.589Z" transform="translate(-202.207 140.311)" fill="#777" />
      </g>
    </svg>

  );
};

export default IconMailConnexion;
