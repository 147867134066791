const IconCancelCross = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="cancel-cross" transform="translate(0)">
        <path id="Soustraction_1" data-name="Soustraction 1" d="M-4002.325-4905.047a9.01,9.01,0,0,1-9-9,9.009,9.009,0,0,1,9-9,9.01,9.01,0,0,1,9,9A9.011,9.011,0,0,1-4002.325-4905.047Zm0-7.924h0l3.5,3.5a.759.759,0,0,0,.539.223.758.758,0,0,0,.538-.223.755.755,0,0,0,.223-.539.752.752,0,0,0-.223-.537l-3.5-3.505,3.5-3.5a.762.762,0,0,0,0-1.076.756.756,0,0,0-.538-.223.758.758,0,0,0-.539.223l-3.5,3.5-3.5-3.5a.757.757,0,0,0-.538-.223.756.756,0,0,0-.538.223.761.761,0,0,0,0,1.076l3.5,3.5-3.5,3.505a.751.751,0,0,0-.223.537.754.754,0,0,0,.223.539.757.757,0,0,0,.538.223.76.76,0,0,0,.539-.223l3.5-3.5Z" transform="translate(4011.324 4923.047)" fill="#e75012" />
      </g>
    </svg>

  );
};

export default IconCancelCross;
